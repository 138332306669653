<template>
	<div class="h-full flex flex-col relative">
		<TopBar :showBack="true"/>
		<AppTitle title="Edit Profile"/>
		<div class="pt-8 overflow-y-scroll flex-grow" v-if="this.user">
			<div class="pb-8 text-center">
				<div class="relative w-32 mx-auto">
					<user-profile-picture 
					:user="user"
					:size="32"
					/>
					<div class=" absolute top-0 left-0">
						<font-awesome-icon icon="fa-solid fa-pencil" class=" text-white p-2 bg-red-500 rounded-full"/>
						<input @change="submitProfilePicture" type="file" class="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md">
					</div>
					<div class="absolute top-0 right-0" @click="removeImage">
						<font-awesome-icon icon="fa-solid fa-close" class="text-white px-2.5 py-2 bg-red-500 rounded-full"/>
					</div>  
				</div>

			</div>
			
			<alert
			:type="alert.type"
			:message="alert.message" 
			v-show="alert.show"
			@dismiss="alert.show=false"
			/>

			<form @submit.stop.prevent="submit">
				<div class="space-y-4">
					<input type="text" class="block w-full py-4 px-6 rounded-3xl border border-gray-100 placeholder-gray-400 text-gray-500 focus:ring-red-500 focus:border-red-500" placeholder="Full Name" v-model.trim="formFields.name" @keydown="clearError('name')">
					<span class="text-xs text-red-500" v-if="errors.name">{{ errors.name[0] }}</span>
					<input type="email" class="block w-full py-4 px-6 rounded-3xl border border-gray-100 placeholder-gray-400 text-gray-500 focus:ring-red-500 focus:border-red-500" placeholder="Email" v-model.trim="formFields.email" @keydown="clearError('email')">
					<span class="text-xs text-red-500" v-if="errors.email">{{ errors.email[0] }}</span>
					<input type="text" class="block w-full py-4 px-6 rounded-3xl border border-gray-100 placeholder-gray-400 text-gray-500 focus:ring-red-500 focus:border-red-500" placeholder="Phone number" v-model.trim="formFields.phone" @keydown="clearError('phone')">
					<span class="text-xs text-red-500" v-if="errors.phone">{{ errors.phone[0] }}</span>
				</div>
				<input type="submit" value="Update" class="mt-8 bg-red-500 text-white py-2 rounded-xl w-full font-gothic text-lg"/>
			</form>
		</div>
	</div>
</template>

<script type="text/javascript">
	import TopBar from '@/components/TopBar.vue';
	import AppTitle from '@/components/AppTitle.vue';
	import UserProfilePicture from '@/components/UserProfilePicture.vue';
	import Alert from '@/components/ui/Alert'
	export default {
		components: {
			TopBar,
			AppTitle,
			UserProfilePicture,
			Alert,
		},
		data(){
			return {
				formFields: {
					name: '',
					email: '',
					phone: ''
				},
				disableImage: false,
				alert: {
					type: '',
					message: '',
					show: false
				},
				errors: {}
			}
		},
		mounted(){
			this.$store.dispatch('auth/getCurrentUser').then( () => {
				this.formFields.name = this.user.name;
				this.formFields.email = this.user.email;
				this.formFields.phone = this.user.phone;
			})
		},
		computed: {
			user(){
				return this.$store.state.auth.user;
			},
			currentUserName(){
				return this.user.name; 
			}
		},
		methods: {
			clearError(key){
				this.alert.show = false;
				delete this.errors[key];
			},
			submit(){
				let formData = new FormData();
				formData.append("name", this.formFields.name);
				formData.append("email", this.formFields.email);
				formData.append("phone", this.formFields.phone);
				this.$store.dispatch('profile/updateUser', formData)
				.then( () => {
					this.alert.type = "success";
					this.alert.message = "Profile updated successfully";
					this.alert.show = true;
				}).catch( e => {
					this.alert.type = "error";
					this.alert.message = "Error updating profile";
					this.alert.show = true;
					this.errors = e.response.data.errors;
				});
			},
			removeImage(){
				this.disableImage = true;
				this.submitProfilePicture();
			},
			submitProfilePicture(event){
				let formData = new FormData();
				if (event && event.target && event.target.files.length){
					formData.append("picture", event.target.files[0]);
				}
				if (this.disableImage){
					formData.append("remove_picture", true);
				}
				this.$store.dispatch('profile/updateUserProfilePicture', formData)
				.then( () => {
					this.alert.type = "success";
					this.alert.message = "Photo updated successfully";
					this.alert.show = true;
					this.disableImage = false;
					this.$store.dispatch('auth/getCurrentUser');
				}).catch( e => {
					this.alert.type = "error";
					this.alert.message = "Error updating photo";
					this.alert.show = true;
					this.errors = e.response.data.errors;
				});
			},
		}
	}
</script>